import * as React from "react";
import { FC } from "react";
import { ExhibitionsEndpoint } from "../../../entrypoints/exhibitions";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.plPL} entrypoint={"exhibitions"} page={"wystawy"}>
    <ExhibitionsEndpoint lang={LANG.plPL} />
  </DefaultLayout>
);
